<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="9" class="d-flex justify-start align-center"><h2>E-Garansi</h2></v-col>
        <v-col cols="3" class="d-flex justify-end align-center">
          <v-btn color="info" small depressed @click="swapSNDialog = true">Swap SN</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title><h5>Filter</h5></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field v-model="search" outlined dense placeholder="Cari" label="Cari" hide-details></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="province_id"
                :items="provincies"
                :loading="searchProvinceLoading"
                :search-input.sync="searchProvince"
                outlined
                dense
                item-text="name"
                item-value="id"
                placeholder="Provinsi"
                label="Provinsi"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="city_id"
                :items="cities"
                :loading="searchCityLoading"
                :search-input.sync="searchCity"
                outlined
                dense
                item-text="name"
                item-value="id"
                placeholder="Kota / Kabupaten"
                label="Kota / Kabupaten"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="district_id"
                :items="districts"
                :loading="searchDistrictLoading"
                :search-input.sync="searchDistrict"
                outlined
                dense
                item-text="name"
                item-value="id"
                placeholder="Kecamatan"
                label="Kecamatan"
                clearable
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="getData" depressed>Filter</v-btn>
          <v-btn
            color="primary"
            small
            depressed
            outlined
            class="ml-3"
            @click="
              search = ''
              province_id = ''
              city_id = ''
              district_id = ''
              getData()
            "
            >Reset</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="data"
        :options.sync="options"
        :server-items-length="total"
        :loading="loadingTable"
        class="elevation-1"
        :items-per-page="limit"
        :footer-props="{
          'items-per-page-options': [5, 10, 50, 100],
        }"
      >
        <template v-slot:[`item.period`]="{ item }">
          {{ shortlessDate(item.start_date) }} s/d {{ shortlessDate(item.end_date) }}
        </template>
        <template v-slot:[`item.product`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            @click="
              productInformation = item.product
              productInformationDialog = true
            "
            >{{ item.product.code }}</v-btn
          >
        </template>
        <template v-slot:[`item.store`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            @click="
              storeInformation = item.store
              storeInformationDialog = true
            "
            >{{ item.store.store_name }}</v-btn
          >
        </template>
        <template v-slot:[`item.option`]="{ item }">
          <v-btn
            icon
            color="primary"
            x-small
            :href="`https://roomi.co.id/e-garansi/cek?sn=${item.product.code}`"
            target="_blank"
          >
            <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
          </v-btn>
          <v-btn
            color="error"
            x-small
            class="ml-2"
            v-if="user.role == 'Super Admin'"
            @click="
              deleteDialog = true
              selectedDelete = item
            "
            icon
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="productInformationDialog">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="productInformationDialog = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Informasi Produk</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <div>Serial Number</div>
              <div class="font-weight-black">{{ productInformation.code }}</div>
            </v-col>
            <v-col cols="12">
              <div>Tipe</div>
              <div class="font-weight-black">{{ productInformation.type }}</div>
            </v-col>
            <v-col cols="12">
              <div>Model</div>
              <div class="font-weight-black">{{ productInformation.model }}</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined small @click="productInformationDialog = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="storeInformationDialog">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="storeInformationDialog = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Informasi Toko</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <div>Nama Toko</div>
              <div class="font-weight-black">{{ storeInformation.store_name }}</div>
            </v-col>
            <v-col cols="12">
              <div>Alamat</div>
              <div class="font-weight-black">{{ storeInformation.store_address }}</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined small @click="storeInformationDialog = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="swapSNDialog">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="swapSNDialog = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Swap SN</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="5" class="pb-0">
              <v-text-field
                v-model="searchSwapSN"
                outlined
                dense
                placeholder="Cari SN"
                label="Cari SN"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="info"
                small
                depressed
                :loading="isLoadingSearchSwapSN"
                :disabled="searchSwapSN == ''"
                @click="searchSN"
                >Cari</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="selectedSwapSN != null">
            <v-col cols="12">
              <v-simple-table light>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>SN Baru</td>
                      <td>
                        <v-text-field
                          v-model="newSwapSN"
                          outlined
                          dense
                          placeholder="Masukkan SN Baru"
                          label="Masukkan SN Baru"
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Nama Pemilik</td>
                      <td>{{ selectedSwapSN.customer_name }}</td>
                    </tr>
                    <tr>
                      <td>No HP</td>
                      <td>{{ selectedSwapSN.customer_phone }}</td>
                    </tr>
                    <tr>
                      <td>Alamat</td>
                      <td>{{ selectedSwapSN.customer_address }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            :disabled="selectedSwapSN == null || newSwapSN == ''"
            @click="submitNewSN"
            :loading="isSubmitNewSNLoading"
            >Simpan</v-btn
          >
          <v-btn color="primary" outlined small @click="swapSNDialog = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="error"
              small
              depressed
              class="mr-2"
              :loading="loadingDeleteButton"
              :disabled="loadingDeleteButton"
              @click="deleteData"
              >Ya</v-btn
            >
            <v-btn color="error" outlined depressed small @click="deleteDialog = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiRefresh,
  mdiFileOutline,
  mdiClose,
  mdiEyeOutline,
  mdiDelete,
} from '@mdi/js'
import axios from 'axios'
import { shortlessDate } from '@/utils'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
        mdiClose,
        mdiEyeOutline,
        mdiDelete,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      loadingTable: false,
      total: 0,
      data: [],
      limit: 10,
      search: '',
      headers: [
        { text: 'Periode Garansi', value: 'period', sortable: false, width: '22%' },
        { text: 'Produk', value: 'product', sortable: false },
        { text: 'Pemilik', value: 'customer_name', sortable: false },
        { text: 'No Handphone', value: 'customer_phone', sortable: false },
        { text: 'Alamat', value: 'customer_address', sortable: false },
        { text: 'Toko', value: 'store', sortable: false },
        { text: 'Opsi?', value: 'option', sortable: false, width: '10%' },
      ],
      options: {},
      productInformation: {
        code: '',
        type: '',
        model: '',
      },
      productInformationDialog: false,
      storeInformation: {
        store_name: '',
        store_address: '',
      },
      storeInformationDialog: false,
      temp: null,
      province_id: '',
      city_id: '',
      district_id: '',
      provincies: [],
      cities: [],
      districts: [],
      searchProvinceLoading: false,
      searchCityLoading: false,
      searchDistrictLoading: false,
      searchProvince: null,
      searchCity: null,
      searchDistrict: null,
      deleteDialog: false,
      loadingDeleteButton: false,
      selectedDelete: null,
      swapSNDialog: false,
      selectedSwapSN: null,
      searchSwapSN: '',
      newSwapSN: '',
      isLoadingSearchSwapSN: false,
      isSubmitNewSNLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
    searchProvince(val) {
      // Items have already been loaded
      this.provincies = []
      if (this.provincies.length > 0) return

      // Items have already been requested
      if (this.searchProvinceLoading) return

      this.searchProvinceLoading = true

      const params = {
        q: val,
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}../province?${paramsString}`)
        .then(res => {
          this.searchProvinceLoading = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.provincies = data.data
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.searchProvinceLoading = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
        })
    },
    searchCity(val) {
      // Items have already been loaded
      this.cities = []
      if (this.cities.length > 0) return

      // Items have already been requested
      if (this.searchCityLoading) return

      this.searchCityLoading = true

      const params = {
        q: val,
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}../city?${paramsString}`)
        .then(res => {
          this.searchCityLoading = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.cities = data.data
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.searchCityLoading = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
        })
    },
    searchDistrict(val) {
      // Items have already been loaded
      this.districts = []
      if (this.districts.length > 0) return

      // Items have already been requested
      if (this.searchDistrictLoading) return

      this.searchDistrictLoading = true

      const params = {
        q: val,
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}../district?${paramsString}`)
        .then(res => {
          this.searchDistrictLoading = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.districts = data.data
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.searchDistrictLoading = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
        })
    },
  },
  methods: {
    getData() {
      this.loadingTable = true
      this.data = []
      const token = localStorage.getItem('token')
      const { page, itemsPerPage } = this.options
      const params = {
        page,
        limit: itemsPerPage,
        q: this.search,
        province_id: this.province_id,
        city_id: this.city_id,
        district_id: this.district_id,
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}guarantee?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingTable = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.total = data.data.count
              this.data = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.loadingTable = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    shortlessDate(date) {
      return shortlessDate(date)
    },
    deleteData() {
      this.loadingDeleteButton = true
      const token = localStorage.getItem('token')
      axios
        .delete(`${process.env.VUE_APP_API_ENDPOINT}guarantee/${this.selectedDelete.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingDeleteButton = false
          this.deleteDialog = false
          if (res.status == 200) {
            this.alert = {
              status: true,
              success: res.data.success,
              message: res.data.message,
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
          this.getData()
        })
        .catch(err => {
          this.loadingDeleteButton = false
          this.deleteDialog = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    searchSN() {
      if (this.searchSwapSN.length == 0) {
        this.alert = {
          status: true,
          success: false,
          message: 'SN harus diisi!',
        }
        return
      }

      this.isLoadingSearchSwapSN = true
      const token = localStorage.getItem('token')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}guarantee/${this.searchSwapSN}/search-by-sn`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.isLoadingSearchSwapSN = false
          if (res.status == 200) {
            this.selectedSwapSN = res.data.data
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.isLoadingSearchSwapSN = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    submitNewSN() {
      this.isSubmitNewSNLoading = true
      const token = localStorage.getItem('token')
      axios
        .put(
          `${process.env.VUE_APP_API_ENDPOINT}guarantee/${this.selectedSwapSN.id}/swap-sn`,
          {
            new_sn: this.newSwapSN,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(res => {
          this.isSubmitNewSNLoading = false
          if (res.status == 200) {
            this.alert = {
              status: true,
              success: true,
              message: 'SN berhasil diubah!',
            }
            this.swapSNDialog = false
            this.selectedSwapSN = null
            this.searchSwapSN = ''
            this.newSwapSN = ''
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.isSubmitNewSNLoading = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>

<style>
</style>